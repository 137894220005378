<template>
    <div class="wrap">
        <div class="user-div">
            <div class="head">
                <img src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-14.png">
            </div>
            <div class="phone" v-if="$store.state.userInfo.phone">{{$store.state.userInfo.phone}}</div>
            <div class="phone" v-else>ID：{{$store.state.userInfo.id}}</div>
        </div>

      <template v-if="$store.state.userInfo.type==3">
        <div class="page-title">我的认证资料</div>
        <div class="tool-div">
          <div class="li">
            <div class="label">卡号</div>
            <div class="value">{{$store.state.userInfo.cardNo}}</div>
          </div>
        </div>
      </template>
      <div class="page-title" v-if="$store.state.userInfo.workNumber">我的认证资料</div>
      <div class="tool-div" v-if="$store.state.userInfo.workNumber">
        <div class="li">
          <div class="label">姓名</div>
          <div class="value">{{$store.state.userInfo.name}}</div>
        </div>
        <div class="li">
          <div class="label">工号</div>
          <div class="value">{{$store.state.userInfo.workNumber}}</div>
        </div>
        <div class="li">
          <div class="label">手机</div>
          <div class="value">{{$store.state.userInfo.phone}}</div>
        </div>
      </div>

        <div class="order-div" v-if="false">
          <div class="title">积分商城订单</div>
          <div class="list">
<!--            <div class="li">-->
<!--              <img src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/order-state-1.png">-->
<!--              <div>待付款</div>-->
<!--            </div>-->
            <div class="li" @click="goOrder(1)">
              <img src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/order-state-2.png">
              <div>待发货</div>
            </div>
            <div class="li" @click="goOrder(2)">
              <img src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/order-state-2.png">
              <div>待收货</div>
            </div>
            <div class="li" @click="goOrder(3)">
              <img src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/order-state-3.png">
              <div>已完成</div>
            </div>
            <div class="li" @click="goOrder(-1)">
              <img src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/order-state-4.png">
              <div>全部订单</div>
            </div>
          </div>
        </div>

      <div class="value-div" v-if="false">
        <div class="li" @click="pushPage('/integralRecord')">
          <div class="left">
            <img src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-21.png">
            <div class="label">我的积分</div>
          </div>
          <div class="right">
            <div class="value">{{$store.state.integralCurrency.integral}}</div>
          </div>
          <img class="jt" src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-15.png">
        </div>
        <div class="li" @click="pushPage('/integral/index')">
          <div class="left">
            <img src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-22.png">
            <div class="label">我的金币</div>
          </div>
          <div class="right">
            <div class="value">{{$store.state.integralCurrency.gold}}</div>
          </div>
          <img class="jt" src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-15.png">
        </div>

      </div>


        <div class="page-title">联系客服</div>
        <div class="tool-div">
<!--            <a href="tencent://message/?uin=3249382864&Site=在线QQ&Menu=yes">-->
<!--            <a href="http://wpa.qq.com/msgrd?v=3&uni=3249382864&site=qq&menu=yes">-->
<!--                <a href="mqqwpa://im/chat?chat_type=wpa&uin=123456&version=1&src_type=web&web_src=oicqzone.com">-->
            <div class="li" v-clipboard:copy="'3249382864'"
                 v-clipboard:success="copySuccess">
                <div class="label">QQ客服</div>
                <div class="value">3249382864</div>
                <img class="jt" src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-15.png">
            </div>
<!--            </a>-->
            <a href="tel:15361811997">
            <div class="li">
                <div class="label">客服热线</div>
                <div class="value">15361811997</div>
                <img class="jt" src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-15.png">
            </div>
            </a>
          <div class="li" v-clipboard:copy="'15361811997'"
               v-clipboard:success="copySuccess">
            <div class="label">微信</div>
            <div class="value">15361811997</div>
            <img class="jt" src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-15.png">
          </div>
        </div>

        <div class="loout" @click="logout">退出登陆</div>





      <certification-comp v-if="certificationAuto&&!$store.state.userInfo.company" @close="certificationAuto=false"></certification-comp>



      <tabbar-comp :index="1"></tabbar-comp>


    </div>
</template>

<script>
import certificationComp from '../../components/certification-comp'
    export default {
        name: 'mine',
        components: {
          certificationComp
        },
        data() {
            return {
              certificationAuto:false
            }

        },

        async created() {

        },

        async mounted() {


        },
        methods: {
          goOrder(state){
            if(!this.$store.state.userInfo.company){
              this.certificationAuto=true
              return false;
            }
            this.$router.push('/orderList?state='+state)

          },
          pushPage(url){
            if(!this.$store.state.userInfo.company){
              this.certificationAuto=true
              return false;
            }
            this.$router.push(url)
          },

            copySuccess() {
                this.$toast({message: '复制成功'})
            },
            logout(){
                this.$store.dispatch('logOut')
                this.$router.replace('/login')
            },
        }
    }
</script>
<style lang="scss" scoped>

    .wrap{
        padding-top: 20px;
      padding-bottom: 30px;
    }

    .user-div{
        width: 688px;
        height: 144px;
        background: #FFFFFF;
        border-radius: 20px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 0 24px;
        box-sizing:border-box;
        .head{
            width: 98px;
            height: 98px;
            border-radius: 100px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .phone{
            font-size: 36px;
            font-weight: 600;
            line-height: 50px;
            color: #111111;
            margin-left: 30px;
            flex: 1;
        }
    }

    .order-div{
      margin: 0 auto;
      width: 688px;
      height: 241px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
      border-radius: 20px;
      margin-top: 20px;
      .title{
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: #999999;
        padding: 30px 31px;
      }
      .list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .li{
          width: 33%;
          text-align: center;
          padding-bottom: 32px;
          img{
            width: 66px;
            height: 66px;
          }
        }
      }
    }

    .value-div{
      margin: 0 auto;
      margin-top: 20px;
      width: 688px;
      box-sizing:border-box;
      background: #FFFFFF;
      opacity: 1;
      padding: 0 25px;
      border-radius:20px;
      .li{
        height: 108px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 51px;
        position: relative;
        border-bottom: 1px solid #F5F5F5;
        &:last-child{
          border-bottom: none;
        }
        .left{
          display: flex;
          align-items: center;
          img{
            width: 30px;
            height: 30px;
          }
          .label{
            font-size: 32px;
            font-weight: 500;
            line-height: 45px;
            color: #111111;
            margin-left: 20px;
          }
        }
        .right{
          .value{
            font-size: 28px;
            font-weight: 400;
            line-height: 40px;
            color: #111111;
          }
        }
        .jt{
          width: 13px;
          height: 21px;
          position: absolute;
          right: 5px;
          top: 0;
          bottom: 0;
          margin: auto;
        }

      }
    }

    .tool-div{
        width: 688px;
        margin: 0 auto;
        border-radius: 20px;
        background: #FFFFFF;
        box-sizing:border-box;
        padding: 0 25px;
        margin-top: 10px;
        a{
            text-decoration: none;
        }
        .li{
            height: 108px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 51px;
            position: relative;
            border-bottom: 1px solid #F5F5F5;
            &:last-child{
                border-bottom: none;
            }
            .label{
                font-size: 32px;
                font-weight: 500;
                line-height: 45px;
                color: #111111;
                padding-left: 20px;
            }
            .value{
                font-size: 28px;
                font-weight: 400;
                line-height: 40px;
                color: #111111;
            }
            .jt{
                position: absolute;
                right: 5px;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 13px;
            }
        }
    }

    .loout{
        width: 688px;
        height: 108px;
        margin: 0 auto;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 20px;
        font-size: 32px;
        font-weight: 500;
        color: #C70C0C;
        box-sizing:border-box;
        padding-left: 45px;
        display: flex;
        align-items: center;
        margin-top: 20px;
    }


    .page-title{
      padding-left: 32px;
      color: #999999;
      padding-top: 20px;
    }
</style>
